import React from 'react';

type CardItemProps = {
  title: string;
  value: string;
  image: string;
};
const CardItem: React.FC<CardItemProps> = ({ title, value, image }) => {
  return (
    <div className={'flex flex-col h-full gap-2 w-1/2'}>
      <h3 className={'text-gray-400'}>{title}</h3>

      <div className={'flex flex-row gap-1 items-end'}>
        <img src={image} className={'w-8'} />
        <h3 className={'text-wrap break-words overflow-ellipsis'}>{value}</h3>
      </div>
    </div>
  );
};

export default CardItem;
